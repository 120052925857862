import React, { useEffect } from 'react';
import { Container } from 'components/Container';
import { Header } from 'sections/Header';
import { ScrollRestoration, useLocation, useNavigate } from 'react-router-dom';

export const Layout = ({ children }: { children: React.ReactNode}) => {
    const location = useLocation();
    const openSupportForm = () => {
        window.parent.postMessage('callContactSupport', '*');
    };
    const navigate = useNavigate();

    useEffect(() => {
        const handleHashChange = () => {
            if (window.location.hash === '#supportForm') {
                navigate('/');
                openSupportForm();
            }
        };

        handleHashChange();

        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, [location]);

    return (
        <>
            <ScrollRestoration />
            <Header />
            <Container>
                {children}
            </Container>
        </>
    );
};
